import React from "react";
import { Link } from "react-scroll";
export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            <div className="row">
              <div className="col-lg-3">
                <h5>Angel Pupp</h5>
                {/* <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p> */}
              </div>
              <div className="col-lg-5">
                <ul className="head_ul">
                  <li>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="why"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Why & How?
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="chart"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Tokens
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="roadmap"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Roadmap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="faq"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                {/* <div class="input-group mb-3">
                   <input type="text" class="form-control" placeholder="Enter Your Email" 
                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
                   <span class="input-group-text" id="basic-addon2"><i class="fas fa-paper-plane"></i></span>
                  </div> */}
                <ul className="social_links">
                  {/* <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> */}
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/AngelPupp_meme"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://t.me/angelpupp_gang">
                      <i class="fab fa-telegram"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" target="_blank">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li> */}
                  {/* <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="footer_panel_blw">
            {/* <p>Terms & Conditions  |  Risk Disclaimer  |  Privacy Policy</p> */}
            <ul>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Risk Disclaimer</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
            <p>
              Copy right © {new Date().getFullYear()} <a href="#">Angel Pupp</a>
              , All rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
