import axios from "axios";

// import lib
import config from "../lib/config";

//LoginAction

export const GetFaq = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/GetFaq`,
    });
    return {
      loading: true,
      result: respData.data.Data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const SendRefererValue = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/SendRefererValue`,
      data,
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const GetPurchaseHistrory = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/GetPurchaseHistrory`,
      data,
    });

    return {
      loading: true,
      result: respData.data.Data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const SendClaimHistory = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/SendClaimHistory`,
      data,
    });
    console.log(respData, "respData");
    return {
      loading: true,
      result: respData.data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const GetClaimHistrory = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/GetClaimHistrory`,
      data,
    });
    return {
      loading: true,
      result: respData.data.Data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const ClaimStatusGet = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/ClaimStatusGet`,
      // data,
    });

    console.log(respData, "respData=====");
    return {
      loading: true,
      result: respData.data.Data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err?.response?.data?.errors,
    };
  }
};

export const getUpdateDates = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getUpdateDates`,
    });
    return {
      loading: true,
      result: respData.data.Data,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
