import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Countdown, { zeroPad } from "react-countdown";
import {
  GetFaq,
  SendRefererValue,
  GetPurchaseHistrory,
  SendClaimHistory,
  GetClaimHistrory,
  ClaimStatusGet,
  getUpdateDates,
} from "../actions/user";
import Web3 from "web3";
import config from "../lib/config";
import { toast } from "react-toastify";
//ABI
import IcoContractABI from "../ABI/ICOcontract.json";
import BUSDcontractABI from "../ABI/BUSDcontract.json";
import AngelPuppToken from "../ABI/AngelPuppTokenABI.json";
import moment from "moment";
import { Routes, Route, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

let toasterOption = config.toasterOption;

export default function Home(props) {
  const [faq, setfaq] = useState();
  const [busdValue, setbusdValue] = useState("");
  const [AngelpuppValue, setAngelpuppValue] = useState(0);
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState("");
  const [loader, setloader] = useState();
  const [round, setround] = useState();
  const [endTime, setendTime] = useState("");
  const [endTime1, setendTime1] = useState(0);
  const [starttime, setstarttime] = useState();
  // const [currentEndtime, setcurrentEndtime] = useState();
  const [userlimit, setuserlimit] = useState(0);
  const [limitwidth, setwidth] = useState(0);
  const [referer, setreferer] = useState("");
  const [limitepercentage, setlimitepercentage] = useState(0);
  const [refererValue, setrefererValue] = useState();
  const [refererID, setrefererID] = useState(0);
  const [userlimitValue, setuserlimitValue] = useState(0);
  const [HistoryData, setHistoryData] = useState("");
  const [AngelTokenBlnce, setAngelTokenBlnce] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [UserRefferalID, setUserRefferalID] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [TokenAmount1, setTokenAmount1] = useState(0);
  const [claimHistory, setClaimHistory] = useState("");
  const [dbStatus, setdbStatus] = useState(true);
  const [ClaimStatus, setClaimStatus] = useState(false);
  const [claimID, setclaimID] = useState("");
  const [claimAmount, setclaimAmount] = useState("");
  const [OveralltokenPercentage, setOveralltokenPercentage] = useState(0);
  const [startTime1, setTime1] = useState("");
  const [startTime2, setTime2] = useState("");
  const [startTime3, setTime3] = useState("");
  const [startTime4, setTime4] = useState("");
  const [startTime5, setTime5] = useState("");
  const [startTime6, setTime6] = useState("");
  const [startTime7, setTime7] = useState("");
  const [startTime8, setTime8] = useState("");
  const [availableTokenAmt, setavailableTokenAmt] = useState(0);
  const [AngelTokenprice, setAngelTokenprice] = useState(0);
  const [maxusdttoken, setmaxusdttoken] = useState(0);
  let { id } = useParams();
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getmyprovider();
    DateGetUpdate();
    getClaimStatus();
    ClaimHistrory();
    PurchaseHistrory();
    getangelpupptoken();
  }, [walletConnection.address]);

  useEffect(() => {
    if (
      provider &&
      address &&
      startTime1 &&
      startTime2 &&
      startTime3 &&
      startTime4 &&
      startTime5 &&
      startTime6 &&
      startTime7 &&
      startTime8
    ) {
      getRoundStaus();
      getAngelPuppBalnce();
      getRefferalId();
      getTokenAmount();
      getOverAllTokensPer();
    }
  }, [
    provider,
    address,
    startTime1,
    startTime2,
    startTime3,
    startTime4,
    startTime5,
    startTime6,
    startTime7,
    startTime8,
  ]);

  // const Faq = async () => {
  //   let { result } = await GetFaq();
  //   if (result) {
  //     setfaq(result);
  //   }
  // };

  async function getmyprovider() {
    var { provider, address } = await connection();
    // console.log(provider, address, "provider,address");
    setprovider(provider);
    setaddress(address);
  }

  async function getangelpupptoken() {
    var web3 = new Web3(config.rpcUrl);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var statge = await ICOcontract.methods.getStage().call();
    if (statge != 0) {
      var price;
      if (statge == 1) {
        var price = await ICOcontract.methods.levelPrice1().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 2) {
        var price = await ICOcontract.methods.levelPrice2().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 3) {
        var price = await ICOcontract.methods.levelPrice3().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 4) {
        var price = await ICOcontract.methods.levelPrice4().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 5) {
        var price = await ICOcontract.methods.levelPrice5().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 6) {
        var price = await ICOcontract.methods.levelPrice6().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 7) {
        var price = await ICOcontract.methods.levelPrice7().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      } else if (statge == 8) {
        var price = await ICOcontract.methods.levelPrice8().call();
        // console.log(price, typeof price, "price1price1");
        setAngelTokenprice(price / 1e12);
      }
    }
  }

  async function ClaimHistrory() {
    var reqData = {
      address: walletConnection.address,
    };
    let { result } = await GetClaimHistrory(reqData);
    if (result) {
      if (walletConnection.address) {
        setClaimHistory(result);
      }
    }
  }

  const DateGetUpdate = async () => {
    const web3 = new Web3(config.rpcUrl);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var startdate1 = await ICOcontract.methods.startdate1().call();
    setTime1(startdate1);
    var startdate2 = await ICOcontract.methods.startdate2().call();
    setTime2(startdate2);
    var startdate3 = await ICOcontract.methods.startdate3().call();
    setTime3(startdate3);
    var startdate4 = await ICOcontract.methods.startdate4().call();
    setTime4(startdate4);
    var startdate5 = await ICOcontract.methods.startdate5().call();
    setTime5(startdate5);
    var startdate6 = await ICOcontract.methods.startdate6().call();
    setTime6(startdate6);
    var startdate7 = await ICOcontract.methods.startdate7().call();
    setTime7(startdate7);
    var startdate8 = await ICOcontract.methods.startdate8().call();
    setTime8(startdate8);
  };

  async function getOverAllTokensPer() {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var overAllToken = await ICOcontract.methods.overAllToken().call();
    setavailableTokenAmt(parseInt(overAllToken) / 1e18);
    var ans = parseInt(overAllToken) / 1e18;
    var result = (ans / config.OverAllTokens) * 100;
    var covertResult = await convert(result);
    setOveralltokenPercentage(covertResult);
  }

  async function getClaimStatus() {
    if (walletConnection.address) {
      var web3 = new Web3(walletConnection.provider);
      var ICOcontract = new web3.eth.Contract(
        IcoContractABI,
        config.ICOcontractAddress
      );
      var claimestatus = await ICOcontract.methods.claimStatus().call({});
      setClaimStatus(claimestatus);

      var ans = await ICOcontract.methods
        .users(walletConnection.address)
        .call();
      setclaimID(ans.id);
      setclaimAmount(ans.Amount);
    }
  }

  async function getRefferalId() {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var Userss = await ICOcontract.methods.users(address).call();
    setUserRefferalID(Userss.id);
  }

  async function getAngelPuppBalnce() {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var balnce = await ICOcontract.methods.users(address).call();
    setAngelTokenBlnce((balnce.Amount / 1e18).toFixed(2));

    //get usdt max balance
    var BUSDcontract = await new web3.eth.Contract(
      BUSDcontractABI,
      config.BUSDcontractAddress
    );
    var BUSDtokenBln = await BUSDcontract.methods.balanceOf(address).call();

    // console.log(BUSDtokenBln / 1e6, "BUSDtdfghghmjghokenBln");
    setmaxusdttoken(BUSDtokenBln / 1e6);
  }

  async function PurchaseHistrory() {
    var reqData = {
      address: walletConnection.address,
    };
    let { result } = await GetPurchaseHistrory(reqData);
    if (result) {
      setHistoryData(result);
    }
  }

  async function getRoundStaus() {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var result = await ICOcontract.methods.getStage().call();
    const currentTime = Date.now();
    if (currentTime.toString() >= config.startDate) {
      setround(result);
      getTimeCount(result);
      getTokenAmount(result);
    } else {
      setround(0);
      getTimeCount(0);
    }
  }
  async function getTokenAmount(round1) {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    if (round1 == 1) {
      var amount = await ICOcontract.methods.levelAmount1().call();
      setTokenAmount(amount / 1e18);
      var amountNew = await convert(amount / 1e18);
      setTokenAmount1(toPlainString(amountNew));
    } else if (round1 == 2) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();

      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();
        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }

      var amount2 = await ICOcontract.methods.levelAmount2().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      const totalvalue = parseInt(amount1) + parseInt(amount2);

      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 3) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();
      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();
        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      const totalvalue =
        parseInt(amount1) + parseInt(amount2) + parseInt(amount3);

      setTokenAmount(totalvalue / 1e18);

      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 4) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();

      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();
        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      if (amount3 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime3.toString())
          .call();
        amount3 = parseInt(amount3) - parseInt(saleAmount);
      }
      var amount4 = await ICOcontract.methods.levelAmount4().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      var amount4 = await convert(amount4);

      const totalvalue =
        parseInt(amount1) +
        parseInt(amount2) +
        parseInt(amount3) +
        parseInt(amount4);
      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 5) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();

      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();
        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      if (amount3 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime3.toString())
          .call();
        amount3 = parseInt(amount3) - parseInt(saleAmount);
      }
      var amount4 = await ICOcontract.methods.levelAmount4().call();
      if (amount4 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime4.toString())
          .call();
        amount4 = parseInt(amount4) - parseInt(saleAmount);
      }
      var amount5 = await ICOcontract.methods.levelAmount5().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      var amount4 = await convert(amount4);
      var amount5 = await convert(amount5);

      const totalvalue =
        parseInt(amount1) +
        parseInt(amount2) +
        parseInt(amount3) +
        parseInt(amount4) +
        parseInt(amount5);
      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 6) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();

      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();
        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      if (amount3 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime3.toString())
          .call();
        amount3 = parseInt(amount3) - parseInt(saleAmount);
      }
      var amount4 = await ICOcontract.methods.levelAmount4().call();
      if (amount4 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime4.toString())
          .call();
        amount4 = parseInt(amount4) - parseInt(saleAmount);
      }
      var amount5 = await ICOcontract.methods.levelAmount5().call();
      if (amount5 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime5.toString())
          .call();
        amount5 = parseInt(amount5) - parseInt(saleAmount);
      }
      var amount6 = await ICOcontract.methods.levelAmount6().call();

      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      var amount4 = await convert(amount4);
      var amount5 = await convert(amount5);
      var amount6 = await convert(amount6);

      const totalvalue =
        parseInt(amount1) +
        parseInt(amount2) +
        parseInt(amount3) +
        parseInt(amount4) +
        parseInt(amount5) +
        parseInt(amount6);

      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 7) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();
      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();

        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      if (amount3 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime3.toString())
          .call();
        amount3 = parseInt(amount3) - parseInt(saleAmount);
      }
      var amount4 = await ICOcontract.methods.levelAmount4().call();
      if (amount4 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime4.toString())
          .call();
        amount4 = parseInt(amount4) - parseInt(saleAmount);
      }
      var amount5 = await ICOcontract.methods.levelAmount5().call();
      if (amount5 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime5.toString())
          .call();
        amount5 = parseInt(amount5) - parseInt(saleAmount);
      }
      var amount6 = await ICOcontract.methods.levelAmount6().call();
      if (amount6 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime6.toString())
          .call();
        amount6 = parseInt(amount6) - parseInt(saleAmount);
      }
      var amount7 = await ICOcontract.methods.levelAmount7().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      var amount4 = await convert(amount4);
      var amount5 = await convert(amount5);
      var amount6 = await convert(amount6);
      var amount7 = await convert(amount7);

      const totalvalue =
        parseInt(amount1) +
        parseInt(amount2) +
        parseInt(amount3) +
        parseInt(amount4) +
        parseInt(amount5) +
        parseInt(amount6) +
        parseInt(amount7);
      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    } else if (round1 == 8) {
      var amount1 = await ICOcontract.methods.levelAmount1().call();
      if (amount1 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime1.toString())
          .call();

        amount1 = parseInt(amount1) - parseInt(saleAmount);
      }
      var amount2 = await ICOcontract.methods.levelAmount2().call();
      if (amount2 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime2.toString())
          .call();
        amount2 = parseInt(amount2) - parseInt(saleAmount);
      }
      var amount3 = await ICOcontract.methods.levelAmount3().call();
      if (amount3 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime3.toString())
          .call();
        amount3 = parseInt(amount3) - parseInt(saleAmount);
      }
      var amount4 = await ICOcontract.methods.levelAmount4().call();
      if (amount4 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime4.toString())
          .call();
        amount4 = parseInt(amount4) - parseInt(saleAmount);
      }
      var amount5 = await ICOcontract.methods.levelAmount5().call();
      if (amount5 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime5.toString())
          .call();
        amount5 = parseInt(amount5) - parseInt(saleAmount);
      }
      var amount6 = await ICOcontract.methods.levelAmount6().call();
      if (amount6 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime6.toString())
          .call();
        amount6 = parseInt(amount6) - parseInt(saleAmount);
      }
      var amount7 = await ICOcontract.methods.levelAmount7().call();
      if (amount7 > 0) {
        var saleAmount = await ICOcontract.methods
          .userLimit(startTime7.toString())
          .call();
        amount7 = parseInt(amount7) - parseInt(saleAmount);
      }
      var amount8 = await ICOcontract.methods.levelAmount8().call();
      var amount1 = await convert(amount1);
      var amount2 = await convert(amount2);
      var amount3 = await convert(amount3);
      var amount4 = await convert(amount4);
      var amount5 = await convert(amount5);
      var amount6 = await convert(amount6);
      var amount7 = await convert(amount7);
      var amount8 = await convert(amount8);

      const totalvalue =
        parseInt(amount1) +
        parseInt(amount2) +
        parseInt(amount3) +
        parseInt(amount4) +
        parseInt(amount5) +
        parseInt(amount6) +
        parseInt(amount7) +
        parseInt(amount8);

      setTokenAmount(totalvalue / 1e18);
      setTokenAmount1(totalvalue / 1e18);
    }
  }

  async function getuserLimit(currentEndtime) {
    var web3 = new Web3(provider);
    var ICOcontract = new web3.eth.Contract(
      IcoContractABI,
      config.ICOcontractAddress
    );
    var userlimit = await ICOcontract.methods.userLimit(currentEndtime).call();
    setlimitepercentage(userlimit);
    if (userlimit != 0) {
      setuserlimit(userlimit);
      var value1 = parseInt(userlimit);
      setwidth((value1 / 1e18 / TokenAmount1) * 100);
    } else {
      setwidth(0);
      setuserlimit(0);
    }
  }

  async function getTimeCount(round) {
    setstarttime(config.startDate);

    if (round == 1) {
      var DateTime = moment.unix(startTime1).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime1.toString());
      settokenPrice(config.Price1);
    } else if (round == 2) {
      var DateTime = moment.unix(startTime2).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime2.toString());
      settokenPrice(config.Price2);
    } else if (round == 3) {
      var DateTime = moment.unix(startTime3).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime3.toString());
      settokenPrice(config.Price3);
    } else if (round == 4) {
      var DateTime = moment.unix(startTime4).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime4.toString());
      settokenPrice(config.Price4);
    } else if (round == 5) {
      var DateTime = moment.unix(startTime5).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime5.toString());
      settokenPrice(config.Price5);
    } else if (round == 6) {
      var DateTime = moment.unix(startTime6).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime6.toString());
      settokenPrice(config.Price6);
    } else if (round == 7) {
      var DateTime = moment.unix(startTime7).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime7.toString());
      settokenPrice(config.Price7);
    } else if (round == 8) {
      var DateTime = moment.unix(startTime8).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(startTime8.toString());
      settokenPrice(config.Price8);
    } else if (round == 0) {
      setround(0);
      var DateTime = moment.unix(config.startDate).toDate();
      setendTime(DateTime.toISOString());
      getuserLimit(config.startDate);
    } else {
      setendTime(0);
    }
  }

  async function myFunction(text) {
    // navigator.clipboard.writeText(`${config.frontend}/${text}`);
    navigator.clipboard.writeText(text);
    toast.success("Copied!", toasterOption);
  }

  async function CopyFunction(text) {
    navigator.clipboard.writeText(text);
    toast.success("Copied!", toasterOption);
  }

  const handlechange = async (e) => {
    try {
      setbusdValue(e.target.value);
      if (provider) {
        if (e.target.value > 0) {
          setTokenValue(e.target.value);
          // var totalangelpupp = parseFloat(e.target.value) * AngelTokenprice;
          // setAngelpuppValue(totalangelpupp.toFixed(2));
        } else {
          setAngelpuppValue(0);
        }
      }
    } catch (err) {
      console.log("errror:", err);
    }
  };

  const setTokenValue = async (amount) => {
    try {
      if (amount > 0) {
        var totalangelpupp = parseFloat(amount) * AngelTokenprice;
        setAngelpuppValue(totalangelpupp.toFixed(2));
      } else {
        setAngelpuppValue(0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }

  const handleClick = async () => {
    if (walletConnection.address) {
      var web3 = new Web3(provider);
      var ICOcontract = new web3.eth.Contract(
        IcoContractABI,
        config.ICOcontractAddress
      );
      var statge = await ICOcontract.methods.getStage().call();
      // console.log(statge, "statge====");

      if (statge == 0) {
        window.location.reload();
        return false;
      }

      setloader(true);
      ///BUSD token balance
      var BUSDcontract = await new web3.eth.Contract(
        BUSDcontractABI,
        config.BUSDcontractAddress
      );
      var BUSDtokenBln = await BUSDcontract.methods.balanceOf(address).call();
      var value = BUSDtokenBln / 1e6;

      if (busdValue == "") {
        setloader(false);
        toast.error("Enter USDT Value", toasterOption);
        return false;
      } else if (busdValue <= 0) {
        setloader(false);
        toast.error("Enter USDT Value", toasterOption);
        return false;
      } else if (busdValue < 50) {
        setloader(false);
        toast.error("Minimum deposit USDT : 50$", toasterOption);
        return false;
      } else if (busdValue > value) {
        setloader(false);
        toast.error("Insufficient balance", toasterOption);
        return false;
      }

      if (AngelpuppValue > TokenAmount1 - parseInt(userlimit / 1e18)) {
        setloader(false);
        toast.error("Token is not available", toasterOption);
        return false;
      }
      var Str = "";

      if (id != null && id != "" && id != undefined) {
        Str = id;

        var newStr = Str.replace("ANGP", "");
        if (isNaN(newStr)) {
          setloader(false);
          toast.error("Invalid referral fsgetg", toasterOption);
          return false;
        }
        var refererValue = newStr;
        if (refererValue <= 0) {
          setloader(false);
          toast.error("Invalid referral", toasterOption);
          return false;
        }
        setrefererValue(refererValue);
      } else if (referer != null && referer != "" && referer != undefined) {
        if (referer <= 0) {
          setloader(false);
          toast.error("Invalid referral", toasterOption);
          return false;
        }
        Str = referer;
        var newStr = Str.replace("ANGP", "");
        if (isNaN(newStr)) {
          setloader(false);
          toast.error("Invalid referral", toasterOption);
          return false;
        }
        var refererValue = newStr;
        if (refererValue <= 0) {
          setloader(false);
          toast.error("Invalid referral", toasterOption);
          return false;
        }

        setrefererValue(refererValue);
      } else {
        var refererValue = 0;
      }

      var ICOcontract = new web3.eth.Contract(
        IcoContractABI,
        config.ICOcontractAddress
      );

      var datas = await ICOcontract.methods.userList(refererValue).call();

      if (
        refererValue != 0 &&
        datas == "0x0000000000000000000000000000000000000000"
      ) {
        setloader(false);
        toast.error("Invalid referral", toasterOption);
        return false;
      }

      try {
        if (walletConnection.provider) {
          setloader(true);

          var web3 = new Web3(walletConnection.provider);
          if (UserRefferalID > 0 && UserRefferalID == refererValue) {
            setloader(false);
            toast.error("Do not enter Your address as referral", toasterOption);
            return false;
          }

          var ICOcontract = new web3.eth.Contract(
            IcoContractABI,
            config.ICOcontractAddress
          );

          var Userss = await ICOcontract.methods
            .users(walletConnection.address)
            .call();
          // console.log(Userss, "Userss");
          if (Userss.id != 0) {
            setrefererID(Userss.id);
          } else {
            setrefererID(0);
          }

          var BUSDcontract = new web3.eth.Contract(
            BUSDcontractABI,
            config.BUSDcontractAddress
          );

          let Viewallowance = await BUSDcontract.methods
            .allowance(walletConnection.address, config.ICOcontractAddress)
            .call();

          var sendAmt = Math.ceil(1000000) * 1e18;
          var amt = await convert(sendAmt);
          // console.log(Viewallowance / 1e18, amt / 1e18, "Viewallowance");

          if (Viewallowance / 1e6 < amt / 1e18) {
            try {
              var gasLimit = await BUSDcontract.methods
                .approve(config.ICOcontractAddress, amt / 1e12)
                .estimateGas({
                  from: walletConnection.address,
                });
              gasLimit = gasLimit + 100000;
              var gasPrice = await web3.eth.getGasPrice();

              await BUSDcontract.methods
                .approve(config.ICOcontractAddress, amt / 1e12)
                .send({
                  from: walletConnection.address,
                  gas: gasLimit,
                  gasPrice: gasPrice,
                })
                .then(async (result) => {
                  // console.log(result, "result");
                  if (result && result != "") {
                    // toast.success("Token Approved Successfully", toasterOption);
                    // setloader(false);
                  }
                });
              // .catch((error) => {
              //   console.log(error, "error");
              //   setloader(false);
              //   toast.error("Approve failed", toasterOption);
              //   return false;
              // });
            } catch (err) {
              console.log(err, "err");
              setloader(false);
              toast.error("Approve failed", toasterOption);
              return false;
            }
          }

          // setloader(true);

          var ICOcontract = new web3.eth.Contract(
            IcoContractABI,
            config.ICOcontractAddress
          );

          const datacalcul = busdValue * 1e18;
          var amt1 = datacalcul.toString();
          amt1 = await convert(amt1);
          // console.log(amt1, "amt1");
          // var referer = 0;

          setdbStatus(false);

          var gasLimit = await ICOcontract.methods
            .depositusdt(amt1, refererValue)
            .estimateGas({
              from: walletConnection.address,
            });
          gasLimit = gasLimit + 100000;
          var gasPrice = await web3.eth.getGasPrice();

          await ICOcontract.methods
            .depositusdt(amt1, refererValue)
            .send({
              from: walletConnection.address,
              gas: gasLimit,
              gasPrice: gasPrice,
            })
            .then(async (result) => {
              if (result.transactionHash && result.status) {
                //API
                const reqData = {
                  round: round,
                  tokenPrice: tokenPrice,
                  referer: Str == "" ? "-" : Str,
                  busdValue: busdValue,
                  AngelpuppValue: AngelpuppValue,
                  address: walletConnection.address,
                  hash: result.transactionHash,
                };
                let result1 = await SendRefererValue(reqData);
                // console.log(result1, "kjhdjgh");

                if (result1.result.success && result.transactionHash) {
                  setdbStatus(true);

                  // console.log(result1, "gffgfgs");
                  toast.success("Token Purchased Successfully", toasterOption);
                  setloader(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              }
            })
            .catch((error) => {
              setdbStatus(true);
              console.log("buyerror:", error);
              toast.error("Token Purchased failed", toasterOption);
              setloader(false);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            });
        }
      } catch (err) {
        setdbStatus(true);
        console.log("errror:", err);
        setloader(false);
      }
    } else {
      toast.warning("Connect your wallet", toasterOption);
    }
  };

  const HandleClaim = async () => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(provider);
        var ICOcontract = new web3.eth.Contract(
          IcoContractABI,
          config.ICOcontractAddress
        );
        var ans = await ICOcontract.methods
          .users(walletConnection.address)
          .call();

        if (ans.id == 0 && ans.Amount == 0) {
          toast.error("Token not Purchased", toasterOption);
          setloader(false);
          return false;
        }
        if (ans.id != 0 && ans.Amount == 0) {
          toast.error("Token already claimed", toasterOption);
          setloader(false);
          return false;
        }

        var web3 = new Web3(walletConnection.provider);
        var ICOcontract = new web3.eth.Contract(
          IcoContractABI,
          config.ICOcontractAddress
        );

        setloader(true);

        var gasLimit = await ICOcontract.methods.claim().estimateGas({
          from: walletConnection.address,
        });
        gasLimit = gasLimit + 100000;
        var gasPrice = await web3.eth.getGasPrice();

        await ICOcontract.methods
          .claim()
          .send({
            from: walletConnection.address,
            gas: gasLimit,
            gasPrice: gasPrice,
          })
          .then(async (result) => {
            // console.log(result, "result");
            if (result.transactionHash && result.status) {
              //API
              const reqData = {
                address: walletConnection.address,
                amount: AngelTokenBlnce,
                hash: result.transactionHash,
              };
              let result1 = await SendClaimHistory(reqData);
              // console.log(result1, "result1");
              if (result1.result.success && result.transactionHash) {
                toast.success("Successfully claimed", toasterOption);
                setloader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            }
          })
          .catch((error) => {
            console.log("error:", error);
            toast.error("Claim rejected", toasterOption);
            setloader(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      }
    } catch (err) {
      console.log(err, "err");
      toast.error("Claim Rejected", toasterOption);
      setloader(false);
    }
  };

  const renderer = ({ days, hours, minutes, seconds }) => {
    if (isNaN(days && hours && minutes && seconds)) {
      // setTimeout(() => {
      days = 0;
      hours = 0;
      minutes = 0;
      seconds = 0;
      // }, 3000);
    }

    // if (walletConnection.address != "") {
    //   if (startTime8 > Date.now().toString()) {
    //     if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
    //       if (TokenAmount1 - parseFloat(userlimit / 1e18) != 0) {
    //         if (dbStatus) {
    //           setTimeout(() => {
    //             // window.location.reload();
    //           }, 3000);
    //         }
    //       }
    //     }
    //   }
    // }

    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label sec">Seconds</span>
        </span>
      </div>
    );
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 4) + "...." + addr.slice(addr.length - 3);
    } else {
      return "";
    }
  };

  const columns = [
    {
      name: "S.no",
      selector: (row, index) => index + 1,
    },
    {
      name: "Address",
      selector: (row) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => CopyFunction(row.address)}
        >
          {halfAddrShow(row.address)}
        </a>
      ),
    },
    {
      name: "Token Sale Price",
      width: "200px",
      selector: (row) => row?.tokenPrice,
    },
    {
      name: "Round",
      selector: (row) => row?.round,
    },
    {
      name: "Referrer Id",
      selector: (row) => row.referer,
    },
    {
      name: "USDT",
      selector: (row) => row.busdValue,
    },
    {
      name: "Angelpupp Token ",
      width: "150px",
      selector: (row) => row.AngelpuppValue,
    },
    {
      name: "Hash",
      selector: (row) => (
        <a
          style={{ color: "blue" }}
          href={`${config.HashLink}${row.hash}`}
          target="_blank"
        >
          {halfAddrShow(row.hash)}
        </a>
      ),
    },
    {
      name: "Date",
      width: "200px",
      selector: (row) => moment(row.createdAt).format("MMMM,Do YYYY, hh:mm A"),
    },
  ];

  const columns1 = [
    {
      name: "S.no",
      selector: (row, index) => index + 1,
    },
    {
      name: "Address",
      selector: (row) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => CopyFunction(row.address)}
        >
          {halfAddrShow(row.address)}
        </a>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Hash",
      selector: (row) => (
        <a
          style={{ color: "blue" }}
          href={`${config.HashLink}${row.hash}`}
          target="_blank"
        >
          {halfAddrShow(row.hash)}
        </a>
      ),
    },

    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("MMMM,Do YYYY, hh:mm A"),
    },
  ];

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
    }
  };

  function toPlainString(num) {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }

  const setmax = () => {
    setbusdValue(maxusdttoken ? maxusdttoken : 0);
    setTokenValue(maxusdttoken);
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="homebg">
        <Navbar />
        <div
          className="page_header"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="head">
              <h1> It’s time for Angels on $Avax to fly...</h1>
            </div>
            <div className="star_img">
              <div className="blinking-star"> </div>
              <img
                src={require("../assets/images/banimg.png")}
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="centerText">
              {/* <h2>Pre-sale will go live this Valentine's day !! </h2> */}
              <div
                className="header_box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {/* <h3 className="h3tag">ICO Pre-Sale is Live</h3>
              <>
                <p className="para">Round 1 sales sterts in:</p>
              </> */}
                <div className="flx_head">
                  <div>
                    {walletConnection?.address == "" ? (
                      <h3 className="h3tag">Connect wallet to view sale</h3>
                    ) : (
                      <h3 className="h3tag">
                        ICO Pre-Sale{" "}
                        {startTime8 < Date.now().toString() ||
                        TokenAmount1 - parseFloat(userlimit / 1e18) == 0
                          ? "ended"
                          : "is Live"}
                      </h3>
                    )}

                    {walletConnection?.address == "" ? (
                      <p className="para"></p>
                    ) : round != 0 && startTime8 > Date.now().toString() ? (
                      <p className="para">Round {round} sales ends in:</p>
                    ) : round == 0 && startTime8 > Date.now().toString() ? (
                      <p className="para">Sale starts in: </p>
                    ) : (
                      <>
                        <p className="para">Sale has been ended </p>
                      </>
                    )}
                  </div>

                  <div className="d-flex flex-column-reverse">
                    <p className="token">
                      Token Price in round {round ? round : 0} : {tokenPrice} $
                    </p>
                    <p className="token blw">
                    Token price increases every stage.
                    </p>
                  </div>

                  <div className="right">
                    <div className="d-flex flex-wrap">
                      <p>Referral Id : </p>
                      <div>
                        {UserRefferalID != 0 ? (
                          <>
                            <span>{`ANGP${parseInt(UserRefferalID)}`}</span>
                            <i
                              class="far fa-copy"
                              onClick={() =>
                                myFunction(`ANGP${parseInt(UserRefferalID)}`)
                              }
                            ></i>
                          </>
                        ) : (
                          <>
                            <span>-</span>
                            <i class="far fa-copy"></i>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    {provider == "" ? (
                      <>
                        <Countdown
                          date={new Date(endTime1)}
                          // autoStart={true}
                          // onStart={() => new Date(endTime)}
                          renderer={renderer}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <Countdown
                          date={new Date(endTime)}
                          // autoStart={true}
                          // onStart={() => new Date(endTime)}
                          renderer={renderer}
                          onComplete={() => {
                            if (startTime8 > Date.now().toString()) {
                              if (
                                TokenAmount1 - parseFloat(userlimit / 1e18) !=
                                0
                              ) {
                                if (dbStatus) {
                                  setTimeout(() => {
                                    window.location.reload(false);
                                  }, 5000);
                                }
                              }
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="right_box">
                      <div class="progress mb-2">
                        {/* {round == 0 || round == undefined ? ( */}
                        {/* <>
                          {" "}
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: "0%",
                            }}
                          >
                            0%
                          </div>
                        </>
                      ) : (
                        <> */}{" "}
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-label="Animated striped example"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: `${Math.round(OveralltokenPercentage)}%`,
                          }}
                        >
                          {`${Math.round(OveralltokenPercentage)}%`}
                        </div>
                        {/* </>
                      )} */}
                      </div>

                      {/* <div className="flx">
                      <p>{Math.round(userlimit / 1e18)} AngelPupp</p>
                      &nbsp;
                      <p>{Math.round(TokenAmount1)} AngelPupp</p>
                    </div> */}
                      <div>
                        {walletConnection.address ? (
                          <>
                            <p>
                              <b>Your Tokens : {Math.round(AngelTokenBlnce)}</b>
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              <b>Your Tokens : 0</b>
                            </p>
                          </>
                        )}
                        <p>
                          <b>
                            Available Tokens :{" "}
                            {TokenAmount1
                              ? (
                                  TokenAmount1 - parseFloat(userlimit / 1e18)
                                ).toFixed(2)
                              : 0}
                          </b>
                        </p>
                        <p>
                          <b>
                            Total Sale Percentage :{" "}
                            {OveralltokenPercentage == 0
                              ? `0 %`
                              : `${parseFloat(OveralltokenPercentage)?.toFixed(
                                  2
                                )} %`}
                          </b>
                        </p>
                        <p>
                          {console.log(
                            TokenAmount1,
                            userlimit / 1e18,
                            "TokenAmount1"
                          )}
                          <b>
                            This round sale :{" "}
                            {parseFloat(userlimit / 1e18)
                              ? `${(
                                  (parseFloat(userlimit / 1e18) * 100) /
                                  TokenAmount1
                                ).toFixed(4)} %`
                              : `0 %`}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <label className="d-flex align-items-center justify-content-between ps-3 pe-4">
                      <span>Contribution:-</span>
                      <span>Min-50$</span>
                    </label>
                    <div class="input-group mb-3 mt-0 pt-0">
                      <input
                        type="text"
                        class="form-control border-end-0"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={handlechange}
                        name="BUSDValue"
                        onKeyUp={onKeyUp}
                        onInput={validPositive}
                        value={busdValue}
                        disabled={
                          startTime8 < Date.now().toString() ||
                          TokenAmount1 - parseFloat(userlimit / 1e18) == 0
                            ? true
                            : false
                        }
                        // onKeyDown={(evt) =>
                        //   ["e", "E", "+", "-", "."].includes(evt.key) &&
                        //   evt.preventDefault()
                        // }
                      />
                      <span
                        class="input-group-text ps-0 pe-1 bdrclr bg-transparent border-end-0 border-start-0 text-dark"
                        onClick={setmax}
                      >
                        <button className="max_btn"> Max</button>
                      </span>

                      <span class="input-group-text">USDT</span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label className="ps-3  text-start w-100">
                      <span>Total Expected:-</span>
                    </label>
                    <div class="input-group mb-3 mt-0 pt-0">
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={
                          AngelpuppValue == "" || AngelpuppValue == 0
                            ? 0
                            : AngelpuppValue
                        }
                        disabled={
                          startTime8 < Date.now().toString() ||
                          TokenAmount1 - parseFloat(userlimit / 1e18) == 0
                            ? true
                            : false
                        }
                      />
                      <span class="input-group-text">Angel Pupp Token</span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div class="input-group mb-3 mt-lg-4 mt-3 pt-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Optional"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setreferer(e.target.value)}
                        // id="referer"
                        value={id}
                        disabled={
                          startTime8 < Date.now().toString() ||
                          TokenAmount1 - parseFloat(userlimit / 1e18) == 0
                            ? true
                            : false
                        }
                      />
                      <span class="input-group-text">Referral</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    {ClaimStatus == true && claimID != 0 && claimAmount == 0 ? (
                      <>
                        <p className="para">Your token has been claimed.</p>
                      </>
                    ) : ClaimStatus == true &&
                      round == 0 &&
                      TokenAmount1 - parseFloat(userlimit / 1e18) == 0 ? (
                      <>
                        <p className="para">
                          Your token has been available for claim.
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                {loader ? (
                  <>
                    <button className="primary_btn" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading... Don't refresh
                    </button>
                  </>
                ) : (
                  <>
                    {walletConnection && walletConnection.address ? (
                      <>
                        <>
                          {round != 0 ? (
                            <>
                              <button
                                className="primary_btn"
                                onClick={handleClick}
                              >
                                Buy Tokens Now
                              </button>
                            </>
                          ) : round == 0 &&
                            TokenAmount1 - parseFloat(userlimit / 1e18) != 0 ? (
                            <>
                              <button
                                className="primary_btn"
                                style={{ background: "#ee83a8" }}
                                disabled
                              >
                                Buy Tokens Now
                              </button>
                            </>
                          ) : (
                            <>
                              {ClaimStatus == true ? (
                                <>
                                  <button
                                    className="primary_btn"
                                    onClick={HandleClaim}
                                  >
                                    Claim
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="primary_btn"
                                    style={{ background: "#ee83a8" }}
                                    disabled
                                  >
                                    Claim
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <button
                          className="primary_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >
                          Connect Wallet
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <section className="table_sec">
          <div className="container">
            <h2 class="h2tag">Purchase History</h2>
            <DataTable
              columns={columns}
              data={HistoryData}
              noHeader
              pagination={true}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </section>

        <section className="table_sec">
          <div className="container">
            <h2 class="h2tag">Claim History</h2>
            <DataTable
              columns={columns1}
              data={claimHistory}
              noHeader
              pagination={true}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </section>

        <section className="about_sec" name="about">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <h2 className="h2tag">About Angel Pupp</h2>
                <p className="para">
                  Meet Angel Pupp, the adorable little doge. She's here to
                  deliver the sweetest gains to all her loving fans. Born on
                  AVAX for low-cost transactions, she's making it easy to play
                  the meme game.
                </p>
                <p className="para">
                  Angel Pupp is no ordinary doge. This little puppy has wings!
                  Let her fly you to the moon and win the meme game once and for
                  all. Angel Pupp can deliver you from losses and carry you to
                  profit.
                </p>
              </div>
              <div
                className="col-md-6 m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="text-center">
                  <div className="star_img">
                    <div className="blinking-star blinking-star-about"> </div>
                    <img
                      src={require("../assets/images/about.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="why_sec"
          name="why"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h2tag">How to Buy</h2>
                {/* <p className="para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis.{" "}
                </p> */}
                <div className="text-center">
                  <div className="star_img">
                    <div className="blinking-star blinking-star-why"> </div>
                    <img
                      src={require("../assets/images/why.png")}
                      alt="img"
                      className="img-fluid mt-3"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <div className="box">
                      {/* <h5 className="h5tag">Secured User Data</h5> */}
                      <p className="para">
                        <span>1</span> Introducing 'apupp' - a Hybrid Meme
                        Animal like no other. Prepare to be entertained!
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <div className="box">
                      {/* <h5 className="h5tag">Easy to use</h5> */}
                      <p className="para">
                        <span>2</span> Download Metamask or your Wallet of
                        Choice
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <div className="box">
                      {/* <h5 className="h5tag">Most Credibility</h5> */}
                      <p className="para">
                        <span>3</span> Connect your Wallet, paste the $apupp
                        Token Address, and load up $apupp Coins.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <div className="box">
                      {/* <h5 className="h5tag">Big Data Insight</h5> */}
                      <p className="para">
                        <span>4</span> That's it! Sit Back, Relax, and Watch the
                        Fireworks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chart_sec" name="chart">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <img
                  src={require("../assets/images/pie.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-md-6 m-auto"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2 className="h2tag">Token Distributions</h2>
                {/* <p className="para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida.{" "}
                </p> */}
                <ul>
                  <li>Presale (90%)</li>
                  <li>
                    Liquidity,Exchange Fund & <br /> community Rewards (10%)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className="roadmap_sec"
          name="roadmap"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div className="container">
            <div className="text-center">
              <h2 className="h2tag">Road map</h2>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.{" "}
              </p> */}
            </div>

            <div className="star_img">
              <div className="blinking-star blinking-star-roadmap"> </div>
              <img
                src={require("../assets/images/roadmap.png")}
                alt="img"
                className="img-fluid roadmap_img"
              />
            </div>

            <div className="roadmap_res text-center">
              <div className="star_img">
                <div className="blinking-star"> </div>
                <img
                  src={require("../assets/images/roadmapimg_res.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div
                className="box"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <h5>Phase 1:</h5>
                <p>Token Presale</p>
                <p>Token Launch</p>
                <p>CEX listing</p>
                <p>CMC and CG listing</p>
              </div>
              <div
                className="box"
                data-aos-duration="1500"
                data-aos-delay="600"
              >
                <h5>Phase 2:</h5>
                <p>5000 holders</p>
                <p>More CEXs & KOLs</p>
              </div>
              <div
                className="box"
                data-aos-duration="1500"
                data-aos-delay="700"
              >
                <h5>Phase 3:</h5>
                <p>AngelPupp NFT collection launch</p>
                <p>To the moon with AngelPupp's wings</p>
              </div>
              {/* <div
                className="box"
                data-aos-duration="1500"
                data-aos-delay="800"
              >
                <h5>Lorem Ipsum</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
              <div
                className="box"
                data-aos-duration="1500"
                data-aos-delay="900"
              >
                <h5>Lorem Ipsum</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section
          className="faq_sec"
          name="faq"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div className="container">
            <h2 className="h2tag">FAQ</h2>
            <p className="para head ms-0 mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut{" "}
            </p>

            <div className="row">
              <div className="col-md-9">
                {faq?.map((item, index) => (
                  <div class="accordion" id={`accordionExample${index}`}>
                    <div
                      class="accordion-item"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      data-aos-delay="200"
                    >
                      <h2 class="accordion-header" id={`headingOne${index}`}>
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${index}`}
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          {item?.question}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${index}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`headingOne${index}`}
                        data-bs-parent={`#accordionExample${index}`}
                      >
                        <div class="accordion-body">
                          <p className="para">{item?.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-3 text-center">
                <div className="star_img">
                  <div className="blinking-star blinking-star-faq"> </div>
                  <img
                    src={require("../assets/images/faq.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section
          className="faq_sec"
          name="faq"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div className="container">
            <h2 className="h2tag">FAQ</h2>
            {/* <p className="para head ms-0 mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut{" "}
            </p> */}

            <div className="row">
              <div className="col-md-9">
                <div class="accordion" id="accordionExample">
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        1. What is AngelPupp?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          AngelPupp is the only meme coin with wings.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingtwo">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. What is the total supply?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingtwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">100,000,000,000</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. How many tokens are being sold in presale?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">90,000,000,000 (90% of supply)</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. What is the vesting for presale tokens?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          No vesting, tokens will be 100% released on listing.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefive"
                        aria-expanded="false"
                        aria-controls="collapsefive"
                      >
                        5. Where will AngelPupp list after presale?
                      </button>
                    </h2>
                    <div
                      id="collapsefive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          We will list on DEX & CEX as our first exchanges.
                          Follow our socials for announcement news.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingsix">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsesix"
                        aria-expanded="false"
                        aria-controls="collapsesix"
                      >
                        6. Is there any buy or sell tax?
                      </button>
                    </h2>
                    <div
                      id="collapsesix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingsix"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">There is no buy or sell tax.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center">
                <div className="star_img">
                  <div className="blinking-star blinking-star-faq"> </div>
                  <img
                    src={require("../assets/images/faq.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
