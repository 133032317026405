let key = {};

let toasterOption = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

let Envname = "prodction";

if (Envname === "prodction") {
  const API_URL = "https://api.angelpupp.com";
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://www.angelpupp.com",
    ICOcontractAddress: "0x5bb2250E64066121679900B1174702Ce4640f5dD",
    BUSDcontractAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    AngelPuppToken: "0xa6C8D1aC9f8F1cC42072E52417869dD6e7F60768",
    networkVersion: 43114,
    toasterOption: toasterOption,
    startDate: "1695708106",
    OverAllTokens: 90000000000,
    TotalBusdTokens: "11,250,000,000",
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43114,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    HashLink: "https://snowtrace.io/tx/",
  };
} else if (Envname === "demo") {
  const API_URL = "https://angelpupapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://angelpup.pages.dev",
    ICOcontractAddress: "0x7443234a3b700D46f19b75B10E07216988eab48a", //demo
    BUSDcontractAddress: "0x0621e0B62fe05Ef37962606F5D4d1501af18F98d",
    AngelPuppToken: "0xb6630Efb9d73416FF4f77164D64108Be4759d44a",
    networkVersion: 43113,
    toasterOption: toasterOption,
    // livechainid : 56,
    startDate: "1695708106",
    OverAllTokens: 90000000000,
    TotalBusdTokens: "11,250,000,000",
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43113,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontend: "http://localhost:3000",

    ICOcontractAddress: "0x7924097BDfC159a1dbe370504023A7b89753Ec44", //demo
    BUSDcontractAddress: "0x50a41c61cb0154601cc3eefca888ea279e06e8be",
    AngelPuppToken: "0xb6630efb9d73416ff4f77164d64108be4759d44a",
    networkVersion: 43113,
    toasterOption: toasterOption,
    startDate: "1695366086",
    TotalBusdTokens: "11,250,000,000",
    OverAllTokens: 90000000000,
    Price1: "0.0000085",
    Price2: "0.0000090",
    Price3: "0.0000095",
    Price4: "0.0000105",
    Price5: "0.0000115",
    Price6: "0.0000125",
    Price7: "0.0000135",
    Price8: "0.0000145",
    NetworkId: 43113,
    Symbol: "AVAX",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    // rpcUrl: "https://bsc-testnet.publicnode.com",
    // HashLink: "https://testnet.bscscan.com/tx/",
  };
}

export default key;
